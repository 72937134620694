import { signIn } from 'next-auth/react';
import { FormEvent, useState } from 'react';
import toast from 'react-hot-toast';
import { useLocalStorage } from 'react-use';
import { Button } from '~/shared/ds';

export const ROUTE_LOGIN = '/signin';

interface Props {
	title: string;
	description: string;
	callbackUrl: string;
}

/**
 *
 * @returns
 */
export function GenericSignInForm(props: Props) {
	const { login } = useEmailLogin(props.callbackUrl);

	async function onSubmit(email: string, password: string) {
		toast('Logging you in...');
		await login(email, password);
	}

	return (
		<div className="w-full h-screen flex flex-col justify-center items-center">
			<div className="flex flex-col gap-8 w-[500px] items-stretch">
				<header className="flex flex-col flexmax-w-full items-center">
					<h1 className="text-3xl font-bold">{props.title}</h1>
					<h2 className="text-lg">{props.description}</h2>
				</header>
				<div className="flex-col p-10 bg-foreground rounded-lg shadow-sm border border-primary">
					<EmailForm onSubmit={onSubmit} />
				</div>
				<footer className="flex flex-col items-center ">
					<p className="text-sm opacity-30">
						This is demo application of{' '}
						<a className="underline" href="https://portalform.com">
							Portalform
						</a>
						.
					</p>
				</footer>
			</div>
		</div>
	);
}

interface EmailFormProps {
	onSubmit: (email: string, password: string) => Promise<void>;
}

export const EmailForm = ({ onSubmit }: EmailFormProps) => {
	const [email, setEmail] = useLocalStorage('comm-email', '');
	const [password, setPassword] = useLocalStorage('comm-password', '');
	const [isDisabled, setIsDisabled] = useState(false);

	async function onClickLogin(e: FormEvent) {
		e.preventDefault();
		e.stopPropagation();

		if (!email || !password) {
			return;
		}

		setIsDisabled(true);
		try {
			await onSubmit(email, password);
		} catch (e) {}
		setIsDisabled(false);
	}

	const inputClassName =
		'bg-background focus:outline-none focus:shadow-outline border border-primary rounded-sm focus:border-primary/30 py-2 px-4 block w-full appearance-none leading-normal';

	return (
		<form onSubmit={onClickLogin} className="flex flex-col gap-4">
			<h2>Login to get started:</h2>

			<input
				type="email"
				value={email}
				onChange={(e) => setEmail(e.target.value)}
				placeholder="Enter your email"
				className={inputClassName}
				disabled={isDisabled}
			/>
			<input
				type="password"
				value={password}
				onChange={(e) => setPassword(e.target.value)}
				placeholder="and password"
				className={inputClassName}
				disabled={isDisabled}
			/>

			<Button
				disabled={isDisabled}
				className="color-black hover:bg-blend-darken"
			>
				Login
			</Button>
		</form>
	);
};

function useEmailLogin(callbackUrl: string) {
	return {
		login: async (email: string, password: string) => {
			const res = await signIn('credentials', {
				email,
				password,
				callbackUrl,
			});

			console.log('res', res);
		},
	};
}
