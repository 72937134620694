import { GenericSignInForm } from '~/shared/signin';
import { NextPageWithLayout } from '../_app';

export const ROUTE_LOGIN = '/signin';

const Page: NextPageWithLayout = () => {
	return (
		<GenericSignInForm
			title="🤑 Shoplytics"
			description="All your ecommerce metrics in one place"
			callbackUrl="/ecommerce"
		/>
	);
};

export default Page;
